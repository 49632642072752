<template>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label v-text=" $t('general.button.search') ">
                </label>
                <div class="input-group">
                    <input type="text" class="form-control" :placeholder="$t('general.button.search')"
                        v-model="searchQuery" @change=" fnApiGetCategoryList() ">
                    <div class="input-group-append">
                        <button class="btn btn-success" @click="fnApiGetCategoryList()"> <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" v-for=" (category,index) in categoryList " :key="index + category.id" v-show="!loading">
            <CategoryChildren :category="category" :depth="1" :categorySelected="categorySelected"
                @selected=" catSelcted => categorySelected = catSelcted " />
        </div>
        <div class="col-12" v-show="loading || (categoryList.length == 0) ">
            <no-data :isLoading="loading" sm :dataMessage="$t('noDataMessages.noInformaton')"></no-data>
        </div>

    </div>
</template>

<script>
    import CategoryChildren from "./components/category-children";
    export default {
        components: {
            CategoryChildren,
        },
        data() {
            return {
                rootCategoryList: [],
                searchQuery: null,
                categoryList: [],
                categorySelected: {
                    name: null,
                    id: null,
                },
                loading: true,
            }
        },
        watch: {
            categorySelected() {
                if (this.categorySelected.id) {
                    this.$emit('setCategory', this.categorySelected);

                } else {
                    this.$emit('setCategory', null);

                }
            }
        },
        methods: {
            async fnApiGetCategoryList() {
                this.categorySelected = {
                    name: null,
                    id: null,
                };
                let language = ''
                switch (this.$store.state.language) {
                    case 'en':
                        language = 'en-US';
                        break;
                    case 'es':
                        language = 'es-ES';
                        break;
                }
                let params = {
                    lang: language,
                }

                if (this.searchQuery) {
                    params['q'] = encodeURIComponent(this.searchQuery);
                    params.limit = 250;
                    this.loading = true;
                    await axios.get(`categories`, {
                        params
                    }).then(async (
                        response) => {
                        this.categoryList = response.data;
                    }).catch(error => {});
                    this.loading = false;

                } else {
                    this.loading = true;
                    if (this.rootCategoryList.length > 0) {
                        this.categoryList = [...this.rootCategoryList];
                    } else {
                        await axios.get(`categories/root`, {
                            params
                        }).then(async (
                            response) => {
                            this.rootCategoryList = response.data;
                        }).catch(error => {});

                        this.categoryList = [...this.rootCategoryList];
                    }
                    this.loading = false;
                }
            },
        },
        mounted() {
            this.fnApiGetCategoryList();
        }
    }
</script>

<style>
</style>