<template>
  <b-modal v-model="modals.images" :title="$t('messages.updateVariant')" centered content-class="card form-rounded-inputs card-ecart" size="lg"
    header-class="card-header" @hidden="setVariantId(null)" hide-header-close>

    <v-observer tag="form" ref="formVariant" @submit.prevent="fnValidate()">

      <div class="row">

        <div class="col-12">
          <v-validation rules="required|min:3|max:100" v-slot="{ errors }" :name="$t('general.form.name')">
            <div class="form-group" id="product-name">
              <label>
                <span v-text="$t('general.form.name')"></span>
              </label>
              <input type="text" class="form-control" v-model=" variantInformation.name "
                :class=" { 'is-invalid':errors.length > 0 } ">
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </div>
          </v-validation>
        </div>

        <div class="col-12 col-md-4 ">
          <v-validation rules="required|min:3|max:30" v-slot="{ errors }" :name=" 'SKU' ">
            <div class="form-group">
              <label v-text=" 'SKU' "> </label>
              <div class="input-group ">
                <input type="text" placeholder="AKU1234" class="form-control" v-model=" variantInformation.sku "
                  :class=" { 'is-invalid':errors.length > 0 } ">
                <div class="input-group-append">
                  <span class="input-group-text rounded-right"> <strong>
                      <i class=" fa fa-barcode "></i>
                    </strong> </span>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </v-validation>
        </div>

        <div class="col-12 col-md-4">
          <v-validation rules="required|min_value:0.1" v-slot="{ errors }" :name="$t('general.form.price')">
            <div class="form-group">
              <label>
                <span v-text="$t('general.form.price') + ` (${productInformation.currency})` "></span>
              </label>
              <div class="input-group ">
                <input type="number" placeholder="200" min="0.1" step="0.1" class="form-control"
                  v-model=" variantInformation.price " :class=" { 'is-invalid':errors.length > 0 } ">
                <div class="input-group-append">
                  <span class="input-group-text rounded-right">
                    <strong>
                      <i class=" fa fa-dollar-sign "></i>
                    </strong>
                  </span>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </v-validation>
        </div>

        <div class="col-12 col-md-4">
          <v-validation rules="required|min_value:1" v-slot="{ errors }" :name="$t('general.form.quantity')">
            <div class="form-group">
              <label v-text="$t('general.form.quantity')"> </label>
              <div class="input-group ">
                <input type="number" placeholder="100" class="form-control" v-model=" variantInformation.quantity "
                  :class=" { 'is-invalid':errors.length > 0 } ">
                <div class="input-group-append">
                  <span class="input-group-text rounded-right"> <strong>
                      <i class=" fa fa-hashtag "></i>
                    </strong> </span>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </v-validation>
        </div>

      </div>
      <label v-text="$t('messages.selectImage')"></label>
      <div class="row">
        <div class="col-auto image-container" v-for=" (image,index)  in productInformation.images " :key="index">
          <label :for="'prodImage' + index">
            <img :src="image.url" class=" img-contain-150 border bg-white"
              :class=" (image.id  == variantInformation.image_id) ? 'border-success border-2 p-2' : '' ">
          </label>
          <input type="radio" :id=" 'prodImage' + index " :name="image.id" :value="image.id"
            v-model="variantInformation.image_id">
        </div>
      </div>
      <input type="submit" hidden >

    </v-observer>
    <template v-slot:modal-footer>
      <p class="text-right">
        <b-button variant="none" class=" mr-2" @click=" changeModal({images:false}) "
          v-text="$t('general.button.close')">
        </b-button>
        <button class="btn btn-success" :disabled="loading.variants">
          <span v-if="!loading.variants" @click=" fnValidate()" v-text=" $t('general.button.update') ">
          </span>
          <i v-else class="fa fa-spinner fa-spin "></i>
        </button>
      </p>
    </template>
  </b-modal>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex'
  export default {
    data() {
      return {
        image_id: null,
        variantInformation: {
          name: null,
          sku: null,
          price: null,
          quantity: null,
          image_id: null,
        }
      }
    },
    computed: {
      ...mapState('EcartProduct', ['modals', 'loading', 'productInformation', 'variantId'])
    },
    watch: {
      variantId() {
        this.image_id = null;
        this.variantInformation = {
          ...this.productInformation.variants.find(variant => variant.id == this.variantId)
        };
      }
    },
    methods: {
      ...mapMutations('EcartProduct', ['changeModal', 'setVariantId']),
      ...mapActions('EcartProduct', ['fnApiUpdateVariant']),
      async fnValidate() {
        if (await this.$refs['formVariant'].validate()) {
          this.fnApiUpdateVariant(this.variantInformation);
        }
      },
    },
    mounted() {}
  }
</script>

<style lang="scss" scoped>
  .image-container {
    input {
      position: absolute;
      top: 10px;
      right: 25px;
    }
  }
</style>