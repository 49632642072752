<template>
    <div class="form-rounded-inputs">
        <v-observer tag="form" ref="formProductInformation" @submit.prevent="fnValidateProductInformation()">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <v-validation
                                :rules=" (storeInformation.ecommerce == 'Ecart') ?  'required|min:3|max:100' : 'required|min:3' "
                                v-slot="{ errors }" :name="$t('general.form.name')">
                                <div class="form-group" id="product-name">
                                    <label>
                                        <span v-text="$t('general.form.name')"></span>
                                        <i class=" fa fa-question-circle text-info ml-1" id="popover-productName"></i>
                                        <b-popover placement="topright" target="popover-productName" triggers="hover">
                                            <p v-html="$t('messages.prodcutName')"></p>
                                        </b-popover>
                                    </label>
                                    <input type="text" class="form-control" v-model=" productInformation.name "
                                        :class=" { 'is-invalid':errors.length > 0 } ">

                                    <div class="row justify-content-between">
                                        <div class="col">
                                            <div class="invalid-feedback" :class=" { 'd-block':errors.length > 0 } "
                                                v-if="errors.length > 0">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                        <div class="col-auto" v-if="(storeInformation.ecommerce == 'Ecart')">
                                            <p :class=" { 'text-danger':errors.length > 0 } ">
                                                <span
                                                    v-text=" productInformation.name ? productInformation.name.length : 0 "></span>
                                                <span>/ 100</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-validation>
                        </div>
                        <div class="col-12">
                            <v-validation rules="required" v-slot="{ errors }" :name="$t('general.form.description')">
                                <div class="form-group" id="product-description">
                                    <label>
                                        <span v-text="$t('general.form.description')"></span>
                                        <i class=" fa fa-question-circle text-info ml-1"
                                            id="popover-productDescription"></i>
                                        <b-popover placement="topright" target="popover-productDescription"
                                            triggers="hover">
                                            <p v-html="$t('messages.productDescription')"></p>
                                        </b-popover>
                                    </label>
                                    <vue-editor
                                        v-model="productInformation.description"
                                        :editor-toolbar="CONST.CUSTOMTOOLBAR"
                                        :class=" { 'is-invalid ':errors.length > 0 } "
                                    >
                                    </vue-editor>
                                    <div class="row justify-content-between">
                                        <div class="col">
                                            <div class="invalid-feedback" :class=" { 'd-block':errors.length > 0 } "
                                                v-if="errors.length > 0">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                        <div class="col-auto" v-if="(storeInformation.ecommerce == 'Ecart')">
                                            <p :class=" { 'text-danger':errors.length > 0 } ">
                                                <span
                                                    v-text=" productInformation.description ? productInformation.description.length : 0 "></span>
                                                <span>/ 1000</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-validation>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <v-validation
                                :rules=" { 'required': ( (productInformation.tempImages.length == 0 ) && (productInformation.id == null) ) } "
                                v-slot="{ errors, validate }" :name=" $t('general.form.image') ">
                                <div class="form-group">
                                    <label>
                                        <span v-text="$t('general.form.image')"></span>
                                        <i class=" fa fa-question-circle text-info ml-1" id="popover-productImage1"></i>
                                        <b-popover placement="right" target="popover-productImage1" triggers="hover">
                                            <p v-html="$t('components.product.improvements.imageMessage')"></p>
                                            <p class="text-center">
                                                <img src="@/assets/images/green-sweater.webp" class="img-100" alt="">
                                            </p>
                                        </b-popover>
                                    </label>
                                    <ProductDropImages @validate="validate()" class="m-0" fileName="image" multiple
                                        :class=" { 'is-invalid ':errors.length > 0 } " :accept=" ['image/*'] " />
                                    <div class="invalid-feedback" v-if="errors.length > 0">
                                        {{ errors[0] }}
                                    </div>
                                </div>
                            </v-validation>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-4 ">
                            <v-validation
                                :rules=" (storeInformation.ecommerce == 'Ecart') ?  'required|min:3|max:30' : '' "
                                v-slot="{ errors }" :name=" 'SKU' ">
                                <div class="form-group">
                                    <label v-text=" 'SKU' "> </label>
                                    <div class="input-group ">
                                        <input type="text" placeholder="AKU1234" class="form-control"
                                            :disabled="storeInformation.ecommerce != 'Ecart'"
                                            v-model=" productInformation.sku "
                                            :class=" { 'is-invalid':errors.length > 0 } ">
                                        <div class="input-group-append">
                                            <span class="input-group-text "> <strong>
                                                    <i class=" fa fa-barcode "></i>
                                                </strong> </span>
                                        </div>
                                        <div class="invalid-feedback" v-if="errors.length > 0">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </div>
                            </v-validation>
                        </div>

                        <div class="col-12 col-md-4">
                            <v-validation
                                :rules=" (storeInformation.ecommerce == 'Ecart') ?  'required|min_value:0.1' : '' "
                                v-slot="{ errors }" :name="$t('general.form.price')">
                                <div class="form-group">
                                    <label>
                                        <span
                                            v-text="$t('general.form.price') + ` (${storeInformation.currency})` "></span>
                                    </label>
                                    <div class="input-group ">
                                        <input type="number" placeholder="200" min="0.01" step="0.01"
                                            class="form-control" :disabled="storeInformation.ecommerce != 'Ecart'"
                                            v-model=" productInformation.price "
                                            :class=" { 'is-invalid':errors.length > 0 } ">
                                        <div class="input-group-append">
                                            <span class="input-group-text ">
                                                <strong>
                                                    <i class=" fa fa-dollar-sign "></i>
                                                </strong>
                                            </span>
                                        </div>
                                        <div class="invalid-feedback" v-if="errors.length > 0">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </div>
                            </v-validation>
                        </div>

                        <div class="col-12 col-md-4">
                            <v-validation
                                :rules=" (storeInformation.ecommerce == 'Ecart') ?  'required|min_value:1' : '' "
                                v-slot="{ errors }" :name="$t('general.form.quantity')">
                                <div class="form-group">
                                    <label v-text="$t('general.form.quantity')"> </label>
                                    <div class="input-group ">
                                        <input type="number" placeholder="100" class="form-control"
                                            :disabled="storeInformation.ecommerce != 'Ecart'"
                                            v-model=" productInformation.quantity "
                                            :class=" { 'is-invalid':errors.length > 0 } ">
                                        <div class="input-group-append">
                                            <span class="input-group-text "> <strong>
                                                    <i class=" fa fa-hashtag "></i>
                                                </strong> </span>
                                        </div>
                                        <div class="invalid-feedback" v-if="errors.length > 0">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </div>
                            </v-validation>
                        </div>
                    </div>

                    <div class="custom-control custom-checkbox ">
                        <input type="checkbox" class="custom-control-input" id="sellOutOfStock"
                            v-model="productInformation.sell_out_stock" :value="true"
                            :checked="productInformation.sell_out_stock">
                        <label class="custom-control-label" for="sellOutOfStock"
                            v-text="$t('messages.sellOutOfStock')"></label>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.tags') ">
                                <div class="form-group" id="product-tags">
                                    <label for="">
                                        <span v-text="$t('general.form.tags')"></span>
                                        <span v-text=" ' ( ' + $t('general.form.minimum') + ' 5 )' "> </span>
                                        <i class=" fa fa-question-circle text-info ml-1"
                                            v-b-popover.hover.top=" $t('components.product.tagsMessage') "></i>
                                    </label>
                                    <div class="input-group ">
                                        <b-form-tags v-model="productInformation.tags" class=" "
                                            :placeholder="$t('general.form.addTags') " tag-variant="primary"
                                            separator="," :class=" { 'is-invalid border-danger':errors.length > 0 } ">
                                        </b-form-tags>
                                        <div class="input-group-append">
                                            <span class="input-group-text ">
                                                <i class="fa fa-tags"></i> </span>
                                        </div>
                                        <div class="invalid-feedback " v-if="errors.length > 0">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </div>
                            </v-validation>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group" id="product-category">

                                <v-validation :rules=" (productInformation.category.id == null )? 'required' : '' "
                                    v-slot="{ errors }" :name=" $t('general.form.category') ">
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="">
                                                <span v-text="$t('general.form.category')"></span>
                                                <i class=" fa fa-question-circle text-info ml-1"
                                                    v-b-popover.hover.top=" $t('informational.form.productCategory') "></i>
                                            </label>
                                        </div>
                                        <div class="col-auto ml-auto">
                                            <button class="btn btn-link px-0" type="button"
                                                v-text=" productInformation.category.id ?  $t('general.change') : $t('general.form.select')  "
                                                @click="changeModal({category:true})">
                                            </button>
                                        </div>
                                    </div>
                                    <p class="f-w-600 m-0">
                                        <span v-text="productInformation.category.name" v-if="productInformation.category.name"></span>
                                        <span class="text-muted" v-text="$t('general.none')" v-else></span>
                                    </p>
                                    <div class="form-group">
                                        <input type="text" hidden v-model="productInformation.category.id"
                                            :class=" errors.length > 0 ? 'is-invalid border-danger ': '' ">
                                        <div class="invalid-feedback " v-if="errors.length > 0">
                                            {{ errors[0] }}
                                        </div>
                                    </div>

                                </v-validation>
                            </div>
                        </div>
                    </div>

                    <div class="custom-control custom-checkbox ">
                        <input type="checkbox" class="custom-control-input" id="productCondition"
                            v-model="productInformation.condition" :value="true"
                            :checked="productInformation.condition">
                        <label class="custom-control-label" for="productCondition"
                            v-text="$t('messages.productCondition')"></label>
                    </div>
                </div>
            </div>

            <ProductAttributes />

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>
                                    <span v-text="$t('general.form.dimensions')"></span>
                                    <i class=" fa fa-question-circle text-info ml-1" id="popover-productDimensions"></i>
                                    <b-popover placement="top" target="popover-productDimensions" triggers="hover">
                                        <p v-html="$t('components.product.dimesnionsMessage')"></p>
                                        <p class="text-center">
                                            <img src="@/assets/images/box.webp" class="img-contain-200 border-0" alt="">
                                        </p>
                                    </b-popover>
                                </label>

                                <div class="row">
                                    <div class="col-6 ">
                                        <v-validation rules="required" v-slot="{ errors }"
                                            :name=" $t('general.form.width') ">
                                            <div class="form-group mb-1 mb-sm-2" id="product-width">
                                                <label for="">
                                                    <i class="fa fa-sm fa-arrows-alt-h mr-1 "></i>
                                                    <span v-text="$t('general.form.width')"></span>
                                                </label>
                                                <div class="input-group">
                                                    <input type="number" min="1" step="1" class="form-control"
                                                        v-model=" productInformation.dimensions.width "
                                                        :class=" { 'is-invalid':errors.length > 0 } ">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text  f-w-500 " id="unitsWidth"> <span
                                                                v-text=" CONST.UNITS.LENGTH "> </span> </span>
                                                    </div>
                                                    <div class="invalid-feedback" v-if="errors.length > 0">
                                                        {{ errors[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                        </v-validation>
                                    </div>
                                    <div class="col-6 ">
                                        <v-validation rules="required" v-slot="{ errors }"
                                            :name=" $t('general.form.height') ">
                                            <div class="form-group mb-1 mb-sm-2" id="product-height">
                                                <label for="">
                                                    <i class="fa fa-sm fa-arrows-alt-v mr-1 "></i>
                                                    <span v-text="$t('general.form.height')"></span>
                                                </label>
                                                <div class="input-group">
                                                    <input type="number" min="1" step="1" class="form-control"
                                                        v-model=" productInformation.dimensions.height  "
                                                        :class=" { 'is-invalid':errors.length > 0 } ">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text  f-w-500" id="unitsHeight"> <span
                                                                v-text=" CONST.UNITS.LENGTH "> </span> </span>
                                                    </div>
                                                    <div class="invalid-feedback" v-if="errors.length > 0">
                                                        {{ errors[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                        </v-validation>
                                    </div>
                                    <div class="col-6 ">
                                        <v-validation rules="required" v-slot="{ errors }"
                                            :name=" $t('general.form.length') ">
                                            <div class="form-group mb-1 mb-sm-2" id="product-length">
                                                <label for="">
                                                    <i class=" fa fa-ruler-vertical fa-sm mr-1"></i>
                                                    <span v-text=" $t('general.form.length') "></span>
                                                </label>
                                                <div class="input-group">
                                                    <input type="number" min="1" step="1" class="form-control"
                                                        v-model=" productInformation.dimensions.length  "
                                                        :class=" { 'is-invalid':errors.length > 0 } ">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text  f-w-500" id="unitsDepth"> <span
                                                                v-text=" CONST.UNITS.LENGTH "> </span> </span>
                                                    </div>
                                                    <div class="invalid-feedback" v-if="errors.length > 0">
                                                        {{ errors[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                        </v-validation>
                                    </div>
                                    <div class="col-6 ">
                                        <v-validation rules="required" v-slot="{ errors }"
                                            :name=" $t('general.form.weight') ">
                                            <div class="form-group mb-1 mb-sm-2" id="product-weight">
                                                <label for="">
                                                    <i class="fa fa-weight-hanging fa-sm mr-1"></i>
                                                    <span v-text=" $t('general.form.weight') "></span>
                                                </label>
                                                <div class="input-group">
                                                    <input type="number" min="0.1" step="0.1" class="form-control"
                                                        v-model=" productInformation.dimensions.weight  "
                                                        :class=" { 'is-invalid':errors.length > 0 } ">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text  f-w-500" id="unitsWeight"> <span
                                                                v-text=" CONST.UNITS.WEIGHT "> </span> </span>
                                                    </div>
                                                    <div class="invalid-feedback" v-if="errors.length > 0">
                                                        {{ errors[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                        </v-validation>
                                    </div>
                                    <div class="col-12">
                                        <p class="text-right mt-2" v-if="productInformation.variants.length > 0 ">
                                            <button type="button" @click="changeModal({dimensions:true})"
                                                class="btn btn-link p-0" v-text="$t('tables.variants')"></button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <v-validation rules="required" v-slot="{ errors }"
                                :name="$t('dashboard.product.preparationTitle')">
                                <div class="form-group">
                                    <label for="">
                                        <span v-text="$t('dashboard.product.preparationTitle')"></span>
                                        <i class=" fa fa-question-circle text-info ml-1"
                                            v-b-popover.hover.top=" $t('dashboard.product.preparationMessage')"></i>
                                    </label>
                                    <div class="input-group">
                                        <input type="number" min="0" step="1" class="form-control"
                                            v-model=" productInformation.preparation_time  "
                                            :class=" { 'is-invalid':errors.length > 0 } ">
                                        <div class="input-group-append">
                                            <span class="input-group-text  f-w-500">
                                                <i class=" fa fa-stopwatch "> </i>
                                            </span>
                                        </div>
                                        <div class="invalid-feedback" v-if="errors.length > 0">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </div>
                            </v-validation>
                        </div>
                    </div>
                </div>
            </div>
            <input type="submit" hidden>
        </v-observer>

        <ProductVariants ref="variantsList" />

        <div class="row justify-content-between mb-4">
            <div class="col-4 col-md-3 col-xl-2 " v-if="!newProduct">
                <button class="btn btn-outline-danger w-100" @click="fnDeleteProduct()"
                    :disabled="loading.deleteProduct">
                    <span v-text="$t('general.button.delete')" v-if=" !loading.deleteProduct "></span>
                    <i class="fa fa-spinner fa-spin " v-else></i>
                </button>
            </div>
            <div :class="newProduct ? 'col-12 col-md-4 ml-auto' : 'col-8 col-md-4 pl-0' ">
                <button class=" btn btn-success w-100" :disabled="loading.update" @click="fnValidateProductInformation">
                    <span v-text=" (newProduct) ? $t('general.save') : $t('general.button.update') "
                        v-if=" !loading.update "></span>
                    <i class="fa fa-spinner fa-spin " v-else></i>
                </button>
            </div>
        </div>
        <float-container :show=" showSaveButton && (productInformation.id == null )" classes="bg-black rounded-0">
            <template slot="content" class="">
                <div class="row align-items-center justify-content-center">
                    <div class="col-auto col-md-8 col-lg-9">
                        <h5 class=" text-white m-md-0">
                            <strong v-text="$t('messages.unsavedProduct')"></strong>
                        </h5>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3 ml-auto">
                        <button class="btn btn-success w-100" @click="fnValidateProductInformation">
                            <span v-text="$t('general.save')" v-if=" !loading.update "></span>
                            <i class="fa fa-spinner fa-spin " v-else></i>
                        </button>
                    </div>
                </div>
            </template>
        </float-container>
        <ProductCategoryModal />
    </div>
</template>

<script>
    import {
        mapActions,
        mapMutations,
        mapState
    } from 'vuex';

    import MultiSelect from 'vue-multi-select';
    import 'vue-multi-select/dist/lib/vue-multi-select.css';
    import {
        VueEditor
    } from "vue2-editor";

    import ProductDropImages from './product-drop-images.vue';
    import ProductVariants from './product-variants.vue';
    import ProductAttributes from './product-special-attribs.vue'
    import ProductCategoryModal from './product-category-modal.vue';

    export default {
        components: {
            MultiSelect,
            VueEditor,
            ProductDropImages,
            ProductVariants,
            ProductAttributes,
            ProductCategoryModal,
        },
        data() {
            return {
                showSaveButton: false,
            }
        },
        computed: {
            ...mapState('EcartProduct', ['productTranslations', 'productLanguage', 'productInformation', 'newProduct',
                'storeInformation', 'loading', 'createVariants'
            ]),
        },
        watch: {
            async productInformation() {
                if (this.productInformation.preparation_time) {
                    if (typeof (this.productInformation.preparation_time) == 'string') {
                        let tempPrep = this.productInformation.preparation_time.split('-');
                        tempPrep.length == 2 ? (this.productInformation.preparation_time = (tempPrep[1])) : false;
                    } else {
                        this.productInformation.preparation_time = this.productInformation.preparation_time;
                    }
                } else {
                    this.productInformation.preparation_time = null;
                }
            },
        },

        methods: {
            ...mapMutations('EcartProduct', ['changeModal']),
            ...mapActions('EcartProduct', ['fnApiUpdateProduct', 'fnApiDeleteProduct']),
            async fnValidateProductInformation() {
                if (await this.$refs['formProductInformation'].validate()) {
                    var validateVariants = true;
                    if (this.createVariants) {
                        validateVariants = await this.$refs['variantsList'].fnValidateVariants();
                    }
                    if (validateVariants) {
                        this.fnApiUpdateProduct();
                    }
                } else {
                    this.fnGlobalScrollToError(this.$refs['formProductInformation'].refs, true);
                }
            },

            fnDeleteProduct() {
                this.$bvModal.msgBoxConfirm(this.$t('messages.deleteProduct'), {
                        title: this.$t('general.button.confirmation'),
                        size: 'md',
                        okVariant: 'success',
                        okTitle: this.$t('general.button.delete'),
                        cancelTitle: this.$t('general.button.cancel'),
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    }).then(value => {
                        if (value) {
                            this.fnApiDeleteProduct();
                        }
                    })
                    .catch(err => {})
            }


        },
        // async created() {
        // },
        mounted() {
            document.getElementsByTagName('body')[0].addEventListener('scroll', (e) => {
                if (e.target.scrollTop > 50) {
                    this.showSaveButton = true;
                } else {
                    this.showSaveButton = false;
                }
            });
        }
    }
</script>