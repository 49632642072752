import { render, staticRenderFns } from "./product-variants-edit-modal.vue?vue&type=template&id=5862746d&scoped=true&"
import script from "./product-variants-edit-modal.vue?vue&type=script&lang=js&"
export * from "./product-variants-edit-modal.vue?vue&type=script&lang=js&"
import style0 from "./product-variants-edit-modal.vue?vue&type=style&index=0&id=5862746d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5862746d",
  null
  
)

export default component.exports