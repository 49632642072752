<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <h6 v-text="$t('tables.variants')"> </h6>
                </div>
                <div class="col-auto" v-if="storeInformation.ecommerce == 'Ecart' && (getVariantsList.length > 0 )">
                    <button class="btn btn-link" type="botton"
                        v-text=" !showEditVariants ? $t('general.button.edit') : $t('general.button.cancel')"
                        @click="showEditVariants = !showEditVariants ; setCreateVariants(!createVariants) ">
                    </button>
                </div>
            </div>


            <div v-if="storeInformation.ecommerce == 'Ecart'">
                <div class="custom-control custom-checkbox mb-2" v-if="getVariantsList.length == 0">
                    <input type="checkbox" class="custom-control-input" id="showVariants" v-model="showCreateVariants"
                        @change="setCreateVariants( showCreateVariants )">
                    <label class="custom-control-label" for="showVariants"
                        v-text="$t('messages.variantsMessage')"></label>
                </div>
                <div v-show="createVariants || showEditVariants">
                    <div class="row " v-for=" (optionSelected,optIndex) in optionsSelectedList " :key="optIndex">
                        <div class="col-12 col-lg-4 col-xl-4">
                            <div class="form-group">
                                <label v-text="$t('general.form.option')"></label>
                                <select name="" class="custom-select" :id="'option-' + optIndex"
                                    v-model="optionSelected.name"
                                    @change="variantsChangeOption([optIndex, optionSelected.name])">
                                    <option :value="option.name" v-for="(option,index) in optionsNameList" :key="index"
                                        :disabled=" (option.index != null) || option.index == optIndex"
                                        v-text=" $tc('general.form.' + option.name , 1 )"></option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-lg-8 col-xl-8">
                            <v-observer ref="variantOptions" class="">
                                <v-validation rules="required" v-slot="{ errors }"
                                    :name=" $tc('general.form.' + optionSelected.name , 2 ) ">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col">
                                                <label v-text=" $tc('general.form.' + optionSelected.name , 2 )">
                                                </label>
                                            </div>
                                            <div class="col-auto">
                                                <button class="btn btn-link btn-sm p-0" v-if="optIndex > 0"
                                                    type="button" @click="removeOption(optIndex)"
                                                    v-text="$t('general.button.remove')">
                                                </button>
                                            </div>
                                        </div>
                                        <b-form-tags v-model="optionSelected.options" class=""
                                            :placeholder=" $t('messages.separateOptions') " tag-variant="primary"
                                            separator="," :class=" { 'is-invalid border-danger':errors.length > 0 } ">
                                        </b-form-tags>
                                        <div class="invalid-feedback " v-if="errors.length > 0">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </v-validation>
                            </v-observer>
                        </div>
                    </div>
                    <div class="row" v-if="optionsSelectedList.length < 3 ">
                        <div class=" col-12 col-lg-4">
                            <button class="btn btn-light w-100" v-text="$t('general.button.addAnotherOption')"
                                type="button" @click="variantsAddNewOption()"></button>
                        </div>
                    </div>
                    <hr class="animated fadeIn" v-show="newVariants.length">
                    <v-observer tag="form" ref="formVariantsList" class="mt-2" @submit.prevent="fnValidateVariants()"
                        v-show="newVariants.length > 0">
                        <table class="table ecart-table table-striped animated fadeIn d-none d-md-table">
                            <thead>
                                <tr>
                                    <th class="w-25" v-text="$t('tables.variants')"></th>
                                    <th v-text="$t('tables.price')"></th>
                                    <th v-text="$t('general.form.quantity')"></th>
                                    <th v-text="$t('tables.sku')"></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for=" (variant,index) in newVariants" :key="index">
                                    <td v-text="variant.name"></td>
                                    <td>
                                        <v-validation rules="required|min_value:1" v-slot="{ errors }">
                                            <div class="form-group m-0">
                                                <input type="number" step="1" min="1" v-model="variant.price"
                                                    class="form-control form-control-sm"
                                                    :class=" { 'is-invalid':errors.length > 0 } ">
                                            </div>
                                        </v-validation>
                                    </td>
                                    <td>
                                        <v-validation rules="required|min_value:1" v-slot="{ errors }">
                                            <div class="form-group m-0">
                                                <input type="number" step="1" min="1" v-model="variant.quantity"
                                                    class="form-control form-control-sm"
                                                    :class=" { 'is-invalid':errors.length > 0 } ">
                                            </div>
                                        </v-validation>
                                    </td>
                                    <td>
                                        <v-validation rules="required|min:3" v-slot="{ errors }">
                                            <div class="form-group m-0">
                                                <input type="text" v-model="variant.sku"
                                                    class="form-control form-control-sm"
                                                    :class=" { 'is-invalid':errors.length > 0 } ">
                                            </div>
                                        </v-validation>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-danger circle-30"
                                            @click="deleteNewVariant(index)">
                                            <i class="fa fa-trash "></i> 
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="table ecart-table table-striped d-md-none">
                            <thead>
                                <tr>
                                    <th v-text="$t('tables.variants')"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for=" (variant,index) in newVariants" :key="index">
                                    <td>
                                        <div class="row align-items-end justify-content-between">
                                            <div class="col-12">
                                                <div class="row align-items-end justify-content-between">
                                                    <div class="col-auto">
                                                        <p class="f-w-600">
                                                            <span
                                                                v-text="$t('dashboard.products.variant') + ': ' "></span>
                                                            <span v-text="variant.name"></span>
                                                        </p>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="col-10">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <v-validation rules="required|min:3" v-slot="{ errors }">
                                                            <div class="form-group m-0">
                                                                <label v-text="$t('tables.sku')"></label>
                                                                <input type="text" v-model="variant.sku"
                                                                    class="form-control form-control-sm"
                                                                    :class=" { 'is-invalid':errors.length > 0 } ">
                                                            </div>
                                                        </v-validation>
                                                    </div>

                                                    <div class="col-4">
                                                        <v-validation rules="required|min_value:1" v-slot="{ errors }">
                                                            <div class="form-group m-0">
                                                                <label v-text="$t('dashboard.products.price')"></label>
                                                                <input type="number" step="1" mpin="1"
                                                                    v-model="variant.price"
                                                                    class="form-control form-control-sm"
                                                                    :class=" { 'is-invalid':errors.length > 0 } ">
                                                            </div>
                                                        </v-validation>
                                                    </div>

                                                    <div class="col-4">
                                                        <v-validation rules="required|min_value:1" v-slot="{ errors }">
                                                            <div class="form-group m-0">
                                                                <label v-text="$t('general.form.quantity')"></label>
                                                                <input type="number" step="1" min="1"
                                                                    v-model="variant.quantity"
                                                                    class="form-control form-control-sm"
                                                                    :class=" { 'is-invalid':errors.length > 0 } ">
                                                            </div>
                                                        </v-validation>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-auto pl-1">
                                                <button type="button" class="btn btn-danger p-0 circle-30 "
                                                    @click="deleteNewVariant(index)">
                                                    <i class="fa fa-trash "></i> 
                                                </button>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-observer>
                </div>
            </div>
            <div class="bg-light" v-else>
                <p class="text-muted text-center px-2 py-4 p-md-5 ">
                    <span class="f-w-500" v-text="$t('noDataMessages.variantList')"></span> <br>
                    <small v-text="$t('messages.ecartProductVariants')"></small>
                </p>
            </div>

            <!-- Variants -->
            <table class="table table-striped ecart-table " v-if=" getVariantsList.length > 0 && !showEditVariants  ">
                <tbody>
                    <tr v-for=" (variant,index) in getVariantsList " :key="index + (variant.image_id || variant.id ) "
                        :class=" variant.active ? '':'item-offline'">
                        <td class="">
                            <div class="row ">
                                <div class="col-3 col-sm-2 col-md-2 col-lg-3 col-xl-auto text-center ">
                                    <img v-image="getImage( variant.image_id )" class=" img-cover-70 border" alt="">
                                </div>

                                <div class="col-9 col-sm-10 col-md-10 col-lg-9 col-xl ">
                                    <div class="row ">
                                        <div class="col-7 col-md-8">
                                            <p class=" text-truncate">
                                                <strong class="f-w-600" v-text=" variant.name "></strong>
                                            </p>
                                        </div>
                                        <div class="col-5 col-md-4 ml-auto text-right">
                                            <b-dropdown variant="success circle-30" :text="$t('general.actions')"
                                                no-caret right>
                                                <template v-slot:button-content class="m-0">
                                                    <i class="fa fa-ellipsis-h"></i>
                                                </template>
                                                <b-dropdown-item @click="setVariantId(variant.id)"
                                                    v-if="storeInformation.ecommerce == 'Ecart'">
                                                    <p>
                                                        <i class="fa fa-sm fa-pen mr-2"></i>
                                                        <span v-text="$t('general.button.edit')"></span>
                                                    </p>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click=" fnApiChangeStatusVariant(index) ">
                                                    <p>
                                                        <i class="fa fa-sm fa-power-off mr-2"></i>
                                                        <span
                                                            v-text=" !variant.active ? $t('general.button.activate') : $t('general.button.deactivate') "></span>
                                                    </p>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="fnApiDeleteVariant(index)"
                                                    v-if="storeInformation.ecommerce == 'Ecart'">
                                                    <p>
                                                        <i class="fa fa-sm fa-trash mr-2"></i>
                                                        <span v-text="$t('general.button.delete')"></span>
                                                    </p>
                                                </b-dropdown-item>

                                            </b-dropdown>
                                        </div>

                                    </div>
                                    <div>
                                        <p>
                                            <small>
                                                <span v-text=" $t('dashboard.products.dimensions') + ': ' "></span>
                                                <span v-html=" fnFormatDimenisons( variant.dimensions ) "></span>
                                            </small>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 col-lg-12 col-xl-5 ">
                                            <p>
                                                <small>
                                                    <strong class="f-w-500"
                                                        v-text=" $t('dashboard.products.stock') + ': ' "></strong>
                                                    <span :class=" variant.quantity == 0 ? 'text-danger' : '' "
                                                        v-text=" (  variant.quantity > 0  ) ?  variant.quantity : 0 ">
                                                    </span>
                                                </small>
                                            </p>
                                        </div>
                                        <div class="col-12 col-lg-12 col-xl-7 ml-auto text-right ">
                                            <p class="m-0" v-html="fnGetVariantDiscount(index)">

                                            </p>
                                            <!-- <p v-if=" variant.discount_percent && variant.discount_percent > 0 ">
                                                <small>
                                                    <span v-text=" $t('tables.discount') + ': ' "> </span>
                                                    <strong class=" ml-1 text-success "
                                                        v-text=" variant.discount_percent + '% OFF' "> </strong>
                                                </small>
                                            </p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ProductVariantsEditModal />
            <ProductVariantsDimensions />
        </div>
    </div>
</template>

<script>
    import ProductVariantsEditModal from './product-variants-edit-modal';
    import ProductVariantsDimensions from './product-variants-dimensions-modal.vue';
    import {
        mapState,
        mapActions,
        mapMutations,
        mapGetters
    } from 'vuex'
    export default {
        components: {
            ProductVariantsEditModal,
            ProductVariantsDimensions,
        },
        data() {
            return {
                showEditVariants: false,
                showCreateVariants: false,
            }
        },
        computed: {
            ...mapState('EcartProduct', ['createVariants', 'loading', 'productInformation', 'optionsNameList',
                'newVariant', 'optionsSelectedList', 'newVariants', 'storeInformation'
            ]),
            ...mapGetters('EcartProduct', ['getVariantsList', 'getProductDiscount']),
        },
        watch: {
            createVariants() {
                if (this.createVariants) {
                    this.variantsAddNewOption();
                } else {
                    this.resetVariants();
                    this.showEditVariants = false;
                }
            },
            optionsSelectedList: {
                deep: true,
                handler() {
                    let tempOptions = [];
                    for (let option of this.optionsSelectedList) {
                        if (option.options.length > 0) {
                            tempOptions.push(option.options);
                        }
                    }
                    this.setOptions(tempOptions);
                    if (tempOptions.length > 0) {
                        let tempVariants = this.fnVariants('', tempOptions, []);
                        tempVariants.map((variant, index) => {
                            variant.price = this.productInformation.price;
                            variant.sku = (this.productInformation.sku ? this.productInformation.sku : 'EC') +
                                String(Number(index) + 1);
                            variant.quantity = this.productInformation.quantity;
                            variant.dimensions = {
                                ...this.productInformation.dimensions
                            };
                        });
                        this.setVariants(tempVariants);
                    } else {
                        this.setVariants([]);
                    }
                }
            }
        },
        methods: {
            ...mapMutations('EcartProduct', ['changeModal', 'variantsAddNewOption', 'deleteNewVariant', 'setVariantId',
                'variantsChangeOption', 'setVariants', 'setOptions', 'resetVariants', 'removeOption',
                'editVariants', 'setCreateVariants'
            ]),
            ...mapActions('EcartProduct', ['fnApiChangeStatusVariant', 'fnApiDeleteVariant']),
            async fnValidateVariants() {
                for await (let form of this.$refs['variantOptions']) {
                    if (!(await form.validate())) {
                        return false;
                    }
                }
                if (this.newVariants.length == 0) {
                    return false;
                }
                if (await this.$refs['formVariantsList'].validate()) {
                    return true;
                }
                return false;
            },
            fnVariants(name, namesList, options) {
                let newList = [];
                if (namesList.length > 1) {
                    for (let tempName of namesList[0]) {
                        newList = [...newList, ...this.fnVariants(name ? `${name} / ${tempName}` : tempName, namesList
                            .slice(1), [
                                ...options, tempName
                            ])];
                    }
                } else {
                    for (let tempName of namesList[0]) {
                        let tempVariant = {
                            name: (name ? `${name} / ${tempName}` : tempName),
                            options: [...options, tempName]
                        }
                        newList.push(tempVariant);
                    }
                }
                return newList;
            },
            getImage(image_id) {
                if (this.productInformation.images.length > 0) {
                    let defalutImg = this.productInformation.images[0].url;
                    if (image_id != null) {
                        let tempImage = this.$lodash.find(this.productInformation.images, {
                            id: image_id
                        });
                        if (tempImage != undefined) {
                            return tempImage.url;
                        } else {
                            return defalutImg;
                        }
                    } else {
                        return defalutImg;
                    }
                } else {
                    return null
                }
            },

            fnGetVariantDiscount(index) {
                let discount = false;
                let container = '';
                if (this.getProductDiscount && this.getProductDiscount.variant_ids) {
                    if (this.getProductDiscount.variant_ids.length == 0 || this.getProductDiscount.variant_ids.find(varId => (varId == this.getVariantsList[index].id))) {
                        discount = true;
                    }
                }
                if (discount) {
                    container =
                        `<small><strong class="f-w-600">${ this.fnFormatPrice( (this.getProductDiscount.is_amount) ? (this.getVariantsList[index].price - this.getProductDiscount.percent) : (this.getVariantsList[index].price - ( (this.getVariantsList[index].price /100) * this.getProductDiscount.percent)) , this.productInformation.currency ) }</strong></small>`;
                    container +=
                        `<small><strong class="text-success f-w-600"> ${((this.getProductDiscount.is_amount) ? fnFormatPrice(this.getProductDiscount.percent, this.productInformation.currency ) : (this.getProductDiscount.percent + '%' ) ) + ' OFF'} </strong> </small>`;
                } else {
                    container =
                        `<small><strong class="f-w-600">${this.fnFormatPrice( this.getVariantsList[index].price , this.productInformation.currency )}</strong></small>`;
                }
                return container;
            },
        },
        mounted() {}
    }
</script>