<template>
  <div class="border rounded p-2 ">

    <div class="images-conteiner">
      <div :class=" index == 0 ?  'first-child ' : 'child' " v-for=" (image,index) in productImages"
        :key="(image.id || image.url) + index ">
        <img v-image="image.url" class="rounded border border-dark-gray" :class=" index == 0 ?  '' : '' ">
        <div class=" float-top-right p-2 d-flex">
          <button class="btn circle-25 mr-1 btn-sm border-success border" @click=" fnApiUpdateDefaultImage(index) "
            type="button" :class="(productInformation.imageUrl == image.url) ? 'btn-success':'btn-light' "
            :disabled="loading.deleteImage || (productInformation.imageUrl == image.url)">
            <i class="fa fa-spinner fa-spin " v-if=" (loading.deleteImage != null) && loading.deleteImage == index"></i>
            <i class="fa fa-star fa-sm " v-else></i>
          </button>
          <button class=" btn btn-danger btn-sm circle-25" @click="fnApiDeleteImage(index)" type="button"
            :disabled="loading.deleteImage || (productInformation.imageUrl == image.url) "
            v-if="(productInformation.ecommerce == 'Shopify' || image.fromecart) ">

            <i class="fa fa-spinner fa-spin " v-if=" (loading.deleteImage != null) && loading.deleteImage == index"></i>
            <i class="fa fa-trash fa-sm " v-else></i>

          </button>
        </div>
      </div>

      <div :class=" index == 0 ?  'first-child ' : 'child' " v-for=" (image,index) in productInformation.tempImages"
        :key=" index + image.image ">
        <img v-image="image.image" class="rounded border border-dark-gray">
        <div class=" float-top-right p-2 d-flex">
          <button type="button" class="btn circle-25 mr-1" :class=" image.default ? 'btn-success' : 'btn-light' "
            @click=" fnSetDefaultImage(index) "> <i class="fa fa-star"> </i> </button>
          <button class="btn btn-danger circle-25 " type="button" @click=" fnDeleteFile(index) ">
            <i class="fa fa-sm fa-trash"></i>
          </button>
        </div>
      </div>

      <div class="child" key="uploadfile">
        <div
          class="card px-2 h-100 m-0 shadow-none drag-container d-none d-md-flex align-items-center justify-content-center"
          :class=" dragOver ? ' bg-success':'' " id="DropZone" @dragover="fnOnDragOver" @dragleave="fnOnDragLeave"
          @drop="fnOnDrop" v-if=" multiple || (fileList.length == 0) ">
          <input type="file" @change="fnOnChange" id="assetsFieldHandle" class=" cursor-pointer " ref="newDragFile"
            :multiple="multiple" :accept="acceptList" />
          <label for="assetsFieldHandle" class=" w-100 m-0">
            <div class="drag-message text-center">
              <p class="m-0">
                <span v-html="$t('general.form.dragAndDrop')" v-if=" !loading.newImage "></span>
                <i class="fa fa-spinner fa-spin fa-2x " v-else></i>
              </p>
            </div>
          </label>
        </div>
        <label for="assetsFieldHandle"
          class="btn btn-light square w-100 h-100 d-flex flex-column align-items-center justify-content-center m-0 d-md-none"
          v-if=" multiple || (fileList.length == 0) ">
          <i class="fa fa-image fa-3x" v-if=" !loading.newImage "></i>
          <span v-text="$t('general.button.uploadImage')" v-if=" !loading.newImage "></span>
          <i class="fa fa-spinner fa-spin fa-2x" v-else></i>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapActions,
    mapState
  } from 'vuex';
  export default {
    props: {
      multiple: {
        type: Boolean,
        default: false,
      },
      accept: {
        type: Array,
        required: true,
      },
      maxSize: {
        type: Number,
        default: 1 //MB
      },
      fileName: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        dragOver: false,
        fileList: [],
        aceptAll: false,
      }
    },
    computed: {
      ...mapState('EcartProduct', ['productInformation', 'loading']),
      productImages() {
        let tempImages = [];
        this.productInformation.images.map(image => {
          if (image.url) tempImages.push(image);
        });

        if (this.productInformation.ecommerce == 'shopify') {
          this.productInformation.imageUrl ? tempImages.push(this.productInformation.imageUrl) : null;
        }
        return tempImages;
      },

      acceptList() {
        if ((this.accept.length == 1) && (this.accept[0] == 'image/*')) {
          this.aceptAll = true;
        }
        return this.accept.join(',');
      },
      maxSizeMB() {
        return this.maxSize * 1000000;
      }
    },
    watch: {
      fileList() {
        setTimeout(() => {
          this.$emit('validate');
        }, 200);
      }
    },
    methods: {
      ...mapActions('EcartProduct', ['fnApiUpdateImage', 'fnApiDeleteImage', 'fnApiUpdateDefaultImage']),

      fnDeleteFile(index) {
        let wasDefault = false;
        if (this.productInformation.tempImages[index].default) {
          wasDefault = true;
        }
        this.productInformation.tempImages.splice(index, 1);
        if ((this.productInformation.tempImages.length > 1) && wasDefault) {
          this.productInformation.tempImages[0].default = true;
        }
      },

      fnSetDefaultImage(index) {
        let tempImage = this.productInformation.tempImages[index];
        this.productInformation.tempImages.splice(index, 1);
        this.productInformation.tempImages.unshift(tempImage);
        this.productInformation.tempImages.map((file, fileIndex) => {
          if (fileIndex == 0) {
            file.default = true;
          } else {
            file.default = false;
          }
        });

      },

      fnOnChange() {
        if (this.$refs.newDragFile.files.length > 0) {
          this.fnAddFiles([...this.$refs.newDragFile.files]);
          this.$refs.newDragFile.value = '';
        }
      },
      fnOnDrop(event) {
        event.preventDefault();
        this.fnAddFiles([...event.dataTransfer.files]);
        this.$refs.newDragFile.value = '';
        this.dragOver = false;
      },

      fnOnDragOver(event) {
        if (!this.dragOver) {
          this.dragOver = true;
        }
      },
      fnOnDragLeave(event) {
        if (this.dragOver) {
          this.dragOver = false;
        }
      },

      async fnAddFiles(tempFileList) {
        let newsImages = [];
        for (const index in [...tempFileList]) {
          if (tempFileList[index].size < this.maxSizeMB) {
            const fileType = tempFileList[index].type.split('/')[0];
            const ext = tempFileList[index].name.split('.').pop();

            if ((this.acceptList.includes('.' + ext) || this.aceptAll) && (fileType == 'image')) {
              if (this.multiple || (this.productInformation.tempImages.length == 0)) {

                let newImage = await new Promise((resolve, reject) => {
                  var reader = new FileReader();
                  reader.onloadend = ((event) => {
                    resolve(event);
                  });
                  reader.readAsDataURL(tempFileList[index]);
                });
                if (this.productInformation.id) {
                  newsImages.push(newImage.target.result);
                } else {
                  this.productInformation.tempImages.push({
                    default: (this.productInformation.tempImages.length == 0 ? true : false),
                    image: newImage.target.result
                  });
                }
              }
            }
          } else {
            this.$toasted.global.errorMessage(this.$t('alertMessages.maxSize', {
              size: this.maxSize
            }));
          }
        }
        if (newsImages.length > 0) {
          this.fnApiUpdateImage(newsImages);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .drag-container {
    border-style: dashed;
    border-width: .25rem;
  }

  #assetsFieldHandle {
    position: absolute;
    overflow: hidden;
    opacity: 0;
    height: 100%;
    width: 100%;
  }

  .image-continer {
    position: relative;

    .btn-delete {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  .images-conteiner {
    display: grid;
    grid-gap: 1rem;
    grid-auto-rows: minmax(100px, 150px);
    grid-auto-columns: minmax(100px, 150px);
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: row;
    // width: 100%;

    .first-child {
      position: relative;
      grid-column: 1/span 2;
      grid-row: 1/span 2;
    }

    .child {
      position: relative;
      grid-column: auto;
      grid-row: auto;
    }

    .full-child {
      position: relative;
      grid-column: 4;
      grid-row: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: center;
      background-color: white;
    }

    @media only screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: minmax(150px, 150px);
      grid-auto-columns: minmax(150px, 150px);

      .first-child {
        grid-column: 1/span 2;
        grid-row: 1/span 2;
      }

      .child {
        grid-column: auto;
        grid-row: auto;
      }
    }
  }
</style>