<template>

    <b-modal v-model="modals.category" :title=" $t('dashboard.dashboard.productDetails.category') " centered
        content-class="card form-rounded-inputs card-ecart" header-class="card-header"
        @hidden="fnResetCategoryModal()" cancel-variant="danger" size="md" hide-header-close>
        <CategoryList @setCategory="fnSetCategory" />
        <template v-slot:modal-footer>
            <b-button variant="none" @click="fnResetCategoryModal()" v-text="$t('general.button.close')" ></b-button>
            <b-button :variant=" categorySelected == null ? 'light' :  'success'" :disabled="categorySelected == null" @click="fnChangeCategory()" v-text="$t('general.form.select')"></b-button>
        </template>
    </b-modal>
</template>

<script>
    import {
      mapActions,
      mapMutations,
        mapState
    } from 'vuex'

    import CategoryList from "@/components/category/CategoryList";
    export default {
        components: {
            CategoryList,
        },
        data(){
            return {
                categorySelected: null,
            }
        },
        computed: {
            ...mapState('EcartProduct', ['loading', 'modals']),
        },
        methods: {
            ...mapMutations('EcartProduct', ['setProductCategory','changeModal']),
            ...mapActions('EcartProduct', ['fnApiGetAttributes']),

            fnSetCategory(category){
                this.categorySelected = category;
            },
            fnResetCategoryModal() {
                this.categorySelected = null;
                this.changeModal({category:false});
            },
            fnChangeCategory(){
                this.setProductCategory(this.categorySelected);
                this.fnResetCategoryModal(); 
                this.fnApiGetAttributes();
            }
        }
    }
</script>

<style>

</style>