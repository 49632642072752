<template>
    <div class="tree-menu">
        <div :style="indent">
            <div class="row align-items-center">
                <div class="col-2 col-sm-1 px-0">
                    <button class="btn p-0 w-100" :disabled="loadingChildrens"
                        @click="fnApiGetSubCategories(category.id)"
                        v-show="(category.childrens_id && (category.childrens_id.length > 0)) ">
                        <i class="fa fa-spinner fa-spin" v-if="loadingChildrens"></i>
                        <i class="fa text-muted fa-lg " :class=" showChildren ? 'fa-caret-down' : 'fa-caret-right' "
                            v-else></i>
                    </button>
                </div>
                <div class="col-10 col-sm-11 pl-0">
                    <p class="m-0">
                        <button class="btn p-0 text-left " v-text="category.name" @click=" $emit('selected',category ) "
                            :class=" categorySelected.name == category.name ? 'btn-link text-info' : '' ">
                        </button>
                    </p>
                </div>
            </div>
        </div>
        <category-children v-show="showChildren" v-for="(subcategory,index) in subcategoryList" :depth="depth + 1"
            :key="index + subcategory.name" :category="subcategory" :categorySelected="categorySelected"
            @selected=" catSelcted => $emit('selected',catSelcted ) ">
        </category-children>
    </div>
</template>
<script>
    export default {
        props: ['depth', 'category', 'categorySelected'],
        data() {
            return {
                showChildren: false,
                loadingChildrens: false,
                subcategoryList: [],
            }
        },
        name: 'category-children',
        computed: {
            indent() {
                return {
                    'margin-left': `${this.depth * 25}px`
                }
            }
        },
        methods: {
            async fnApiGetSubCategories(category_id) {
                this.showChildren = !this.showChildren
                if (this.subcategoryList.length == 0) {
                    this.loadingChildrens = true;
                    await axios.get(`categories/${category_id}/childrens`).then(async (
                        response) => {
                        this.subcategoryList = response.data;
                    }).catch(error => {});
                    setTimeout(() => {
                        this.loadingChildrens = false;
                    }, 200);
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .getCategoryChildrens {
        width: 25px;
    }
</style>